<template>
  <div>
    <el-alert
      v-if="showSuccessMail"
      class="alreadyMailText"
      :closable="false"
      type="success"
    >
      <div>
        <i class="far fa-paper-plane action-icon-withour-pointer" />
        {{ $t("forget_password.send_mail_success") }}
      </div>
    </el-alert>
    <el-alert v-if="showIncorrectPassword" :closable="false" type="error">
      <div>
        <i class="fas fa-exclamation-circle action-icon-withour-pointer" />
        {{ $t("login.incorrect_mail_or_password1") }}
        <b
          :underline="false"
          class="forget-password"
          type="success"
          @click="toForgetPassword"
          >{{ $t("login.forget_password") }}</b
        >
      </div>
    </el-alert>
    <el-form
      class="login-form"
      :rules="rules"
      :model="model"
      ref="form"
      :validate-on-rule-change="false"
      @keyup.enter.native="handleLogin()"
    >
      <el-form-item prop="email">
        <el-input
          v-model="model.email"
          :placeholder="$t('login.username')"
          prefix-icon="el-icon-user"
          @blur="trimEmpty()"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="model.password"
          :placeholder="$t('login.password')"
          type="password"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-row>
          <el-col :xs="{ span: 10 }" :sm="{ span: 10 }">
            <el-link
              :underline="false"
              class="forget-password"
              type="success"
              @click="toForgetPassword"
              >{{ $t("login.forget_password") }}</el-link
            >
          </el-col>
          <el-col
            :xs="{ span: 13 }"
            :sm="{ span: 13, offset: 1 }"
            align="right"
          >
            <el-button type="primary" block @click="handleLogin()">
              {{ $t("login.login") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item>
        <el-row type="flex" justify="center">
          <el-col :span="8" style="max-width: 130px;">
            <a :href="lineLogin">
              <el-image
                class="line-login"
                :src="require('@/assets/line_login.png')"
              ></el-image>
            </a>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: [],
  data: () => {
    return {
      showSuccessMail: false,
      showIncorrectPassword: false,
      model: {
        email: "",
        password: ""
      },
      lineLogin: ""
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email_username"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},
  created() {
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }

    if (this.$route.query.fromForgetPassword) {
      this.showSuccessMail = true;
    }
  },

  async mounted() {
    await this.fetchLineLoginAuthURL();
  },

  methods: {
    ...mapActions("user", ["login"]),
    trimEmpty() {
      this.model.email = this.model.email.trim();
    },
    async handleLogin() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      try {
        await this.login(this.model);
      } catch (e) {
        if (e.data.message === "Unauthorized") {
          this.showIncorrectPassword = true;
          return;
        }
      }
      await this.$message({
        message: this.$t("message.login_success"),
        type: "success"
      });
      this.$emit("afterLoginSuccess");
    },
    toForgetPassword() {
      this.$router.push({
        name: "ForgetPassword"
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "LineLogin"
      });

      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });

      this.lineLogin = lineLoginAuthUrl.url;
    }
  }
};
</script>

<style scoped>
.forget-password {
  font-size: 12px;
  color: #42a16a;
  cursor: pointer;
}

.el-button {
  outline: none;
}

::v-deep .el-alert {
  margin-bottom: 8px;
}

.alreadyMailText {
  color: #42a16a;
  font-size: 14px;
}
</style>
