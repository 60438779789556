<template>
  <el-dialog
    class="dialog"
    :title="$t('message.notice')"
    :visible.sync="visible"
    :before-close="() => $emit('toggleDialogVisible', false)">
    <p>{{ $t("studentDetail.pleaseChooseAccountType") }}</p>
    <div class="content">
      <div
        :class="`options ${counselingSchoolsType === 1 ? 'selectedOptions' : ''}`"
        style="margin-right: 8px; cursor: pointer;"
        @click="counselingSchoolsType = 1"
      >
        <span class="icon">
          <i class="fas fa-school" />
        </span>
        <br/>
        <p>{{ $t("studentDetail.toCollege") }}</p>
      </div>
      <div
        :class="`options ${counselingSchoolsType === 2 ? 'selectedOptions' : ''}`"
        @click="counselingSchoolsType = 2"
    >
        <span class="icon">
          <i class="fas fa-building" />
        </span>
        <br/>
        <p>{{ $t("studentDetail.toGradSchool") }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="() => $emit('submit', counselingSchoolsType)">
        {{ $t("button.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      counselingSchoolsType: 1
    };
  },
  computed: {},
  async mounted() {},
  async created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.dialog > div {
  width: 900px;
}

.content {
    display: flex;
}

.options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 4px solid #bababa;
    border-radius: 8px;
    width: 1000px;
    transition: border 0.2s ease;

    .icon {
        color: #bababa;
        font-size: 84px;
        transition: color 0.2s ease;
    }

    p {
        color: #bababa;
        font-size: 16px;
        transition: color 0.2s ease;
        text-align: center;
        padding: 0px 8px;
    }
}

.selectedOptions {
    border: 4px solid #68b488;
    transition: border 0.2s ease;

    .icon {
        color: #68b488;
        transition: color 0.2s ease;
    }

    p {
        font-weight: bolder;
        color: #68b488;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}

@media (max-width: 900px) {
  .options {
    .icon {
        font-size: 52px;
    }

    p {
        font-size: 12px;
    }
  }
}
@media (max-width: 600px) {
  .content {
    display: flex;
    flex-direction: column;
  }
  .options {
    margin: 4px 0px;
    width: 100%;
  }
}

</style>
