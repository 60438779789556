<template>
  <el-form
    class="login-form"
    :rules="rules"
    :model="model"
    ref="form"
    :validate-on-rule-change="false"
    @keyup.enter.native="handleRegister()"
  >
    <el-form-item
      :style="`margin-bottom: ${displayErrorMessage ? 30 : 20}px`"
      prop="email"
      :error="errors.email"
    >
      <span slot="error" slot-scope="scope" class="el-form-item__error">
        <span v-if="displayErrorMessage">
          {{ $t("register.email_is_already_registered_part1") }}
          <router-link :to="{ name: 'Login', query: { email: model.email } }">
            {{ $t("register.email_is_already_registered_part2") }}
          </router-link>
          {{ $t("register.email_is_already_registered_part3") }}
          <router-link
            :to="{ name: 'ForgetPassword', query: { email: model.email } }"
          >
            {{ $t("register.email_is_already_registered_part4") }}
          </router-link>
          {{ $t("register.email_is_already_registered_part5") }}
        </span>
        <span v-else>
          {{ scope.error }}
        </span>
      </span>
      <el-input
        v-model="model.email"
        :placeholder="$t('login.email')"
        prefix-icon="el-icon-user"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="model.password"
        :placeholder="$t('login.password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verifyPassword">
      <el-input
        v-model="model.verifyPassword"
        :placeholder="$t('login.verify_password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="captcha" :error="errors.captcha">
      <el-row>
        <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
          <el-image
            v-if="captchaImg"
            @click="refreshCaptcha"
            :src="captchaImg"
            fit="fill"
          ></el-image>
        </el-col>
        <el-col :xs="{ span: 24 }" :sm="{ span: 15, offset: 1 }">
          <el-input
            v-model="model.captcha"
            :placeholder="$t('login.captcha')"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item align="right">
      <el-button type="primary" block @click="handleRegister">{{
        $t("login.register")
      }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  data: () => {
    return {
      displayErrorMessage: false,
      model: {
        email: "",
        password: "",
        verifyPassword: "",
        captcha: ""
      },
      errors: {
        email: "",
        captcha: ""
      }
    };
  },
  computed: {
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [{ validator: checkVerifyPassword, trigger: "blur" }],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ]
      };
    },
    ...mapGetters({
      captchaImg: "captcha/img",
      captchaKey: "captcha/key"
    })
  },

  mounted() {
    this.$store.dispatch("captcha/getCaptcha");
  },

  methods: {
    ...mapActions("user", ["register"]),

    async handleRegister() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const { email, password, captcha } = this.model;

      const registerReq = {
        email,
        password,
        captcha,
        captchaKey: this.captchaKey,
        lineUserIdToken: localStorage.getItem("lineUserIdToken")
      };

      try {
        await this.register(registerReq);
        localStorage.removeItem("lineUserIdToken");
      } catch (error) {
        const EMAIL_IS_ALREADY_REGISTERED = "the_email_has_already_been_taken";
        const THE_CAPTCHA_CODE_IS_INVALID = "the_captcha_code_is_invalid";

        switch (error.data.message) {
          case EMAIL_IS_ALREADY_REGISTERED:
            this.errors.email = EMAIL_IS_ALREADY_REGISTERED;
            this.displayErrorMessage = true;
            break;
          case THE_CAPTCHA_CODE_IS_INVALID:
            this.errors.captcha = this.$t(
              "register.the_captcha_code_is_invalid"
            );
            break;
          default:
            break;
        }
        return error;
      }

      await this.$message({
        message: this.$t("message.register_success"),
        type: "success"
      });

      this.$router.push({
        name: "RegisterDetail"
      });
    },
    refreshCaptcha() {
      this.$store.dispatch("captcha/getCaptcha");
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          email: "",
          captcha: ""
        };
      }
    }
  }
};
</script>
<style scoped>
.register-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #42a16a;
}

.clearfix {
  color: white;
}

.el-button {
  outline: none;
}

.account {
  margin-bottom: 30px;
}
</style>
